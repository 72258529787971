<template>
    <v-container>
        <validation-observer tag="div" ref="observer">
            <v-card class="mb-3">
                <v-card-text>
                    <v-row>
                        <v-col>
                            <div class="title">Approve</div>
                        </v-col>
                    </v-row>

                    <v-row v-if="!error">
                        <v-col cols="12" sm="6" md="3">
                            Approve text...
                        </v-col>
                    </v-row>
                    <v-row v-else>
                        <v-col cols="12" sm="6" md="3">
                            Approve error text...
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </validation-observer>
    </v-container>
</template>

<script>
export default {
    name: 'Approve',
    data: () => ({
        error: false,
    }),
    mounted() {

        this.$store.dispatch('Profile/approve', this.$route.params.hash)
            .catch(()=>{
                this.error = true
            })
            .finally(() => {
                this.$root.$children[0].hideLoading()
            })
    },
}
</script>

<style>
</style>